import useSWR from 'swr';

// Components
import SkeletonSectionFeatured from '../../components/SectionFeatured/Skeleton';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';
import gridLink from '../gridLink';
import SectionFeaturedMulitReg from '../../components/SectionFeatured/SectionFeaturedMultiReg';

export const FetchFeaturedChannelSeries = (
  params,
  componentInfo,
  hostUrl,
  state = true
) => {
  // API URL.
  const seriesChannelApiUrl = '/api/channelSummits';
  const fullUrl = swrUrlParser(seriesChannelApiUrl, params);

  const { data, error } = useSWR(state ? fullUrl : null, useSWRFetcher());

  if (error) return <></>;

  let featuredData;

  if (error) {
    featuredData = {
      data: [],
      found: 0,
    };
  }

  if (!data || data['series']?.length === 0) {
    featuredData = {
      data: [],
      found: 0,
    };
  } else {
    featuredData = {
      data: data['series'],
    };
  }

  // Set series featured grid link.
  const setGridLink = gridLink(
    null,
    featuredData?.['data']?.[0]?.['id'],
    componentInfo?.type,
    componentInfo?.seriesUTM,
    'search-featured-section',
    0,
    '/series/'
  );

  return (
    <>
      {(() => {
        if (!data) {
          return <SkeletonSectionFeatured />;
        } else if (featuredData['data']?.length > 0) {
          return (
            <SectionFeaturedMulitReg
              summitData={featuredData['data'][0]}
              type="series"
              sectionTitle={componentInfo?.sectionTitle}
              priorityStatus={componentInfo?.priorityStatus}
              dataBddTitle={'featured-talk-title-series'}
              dataBddDesc={'featured-talk-card-description-series'}
              imageUrl={featuredData['data'][0]['imageUrl']}
              gridLink={setGridLink}
              hostUrl={hostUrl}
            />
          );
        } else if (featuredData['data']?.length < 1) {
          <></>;
        }
      })()}
    </>
  );
};

export default FetchFeaturedChannelSeries;
