import React, { FC } from 'react';
import { useParams } from 'next/navigation';

// fetch Lib files
import FetchFeaturedChannelTalk from '../../libs/FetchFeaturedChannelTalk/FetchFeaturedChannelTalk';
import FetchChannelTalksByStatuses from '../../libs/FetchChannelTalksByStatuses/FetchChannelTalksByStatuses';
// This is a protem hack to fetch the upcoming talks in one block until the API can ne re-written
import FetchChannelTalksByStatusUpcoming from '../../libs/FetchChannelTalksByStatusUpcoming/FetchChannelTalksByStatusUpcoming';
import FetchSeriesRelated from '../../libs/FetchSeriesRelated/FetchSeriesRelated';
//import relatedSeries from '../../services/relatedSeries';

interface SingleChannelAllProps {
  hostUrl: string;
  seriesUrl: string;
}
const SingleChannelAll: FC<SingleChannelAllProps> = ({
  hostUrl,
  seriesUrl,
}) => {
  type Params = { channelId: string };
  const params = useParams<Params>();

  // UTM params for featured single channel talk.
  const featuredChannelTalkUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=featured';

  /**
   * Function to prepare the params for featured talk in channel.
   * @returns featured channel params.
   */

  const paramsToFetchDataFeaturedTalk = {
    page: '1',
    filter: 'closestToNow',
    size: '1',
    channelId: params?.channelId,
  };
  const featuredChannelTalkComponentInfo = {
    priorityStatus: true,
    channelTalkUTM: featuredChannelTalkUTM,
    type: 'channel',
    channelId: params?.channelId,
  };

  /**
   * Function to prepare the params for upcoming/recorded channel talks.
   * @param rank channel datetime/-datetime.
   * @param status channel upcoming/recorded.
   * @returns upcoming/recorded channel params.
   */
  function paramsToFetchDataChannelTalks(status: string) {
    return {
      page: 1,
      size: 8,
      webcastStatus: status,
      channelId: params?.channelId,
    };
  }
  // This is tem until new endpoint built when we should revert to the above params
  function paramsToFetchDataChannelTalksUpcoming(status: string) {
    return {
      page: 1,
      size: 64,
      webcastStatus: status,
      channelId: params?.channelId,
    };
  }
  /**
   * Function to prepare the params for featured talk in channel.
   * @returns featured channel params.
   */

  const upcomingChannelTalksComponentInfo = {
    gridSize: 8, // this allows us to modify the block size when we fetch all talks to begin with - can be removed after we revert to older pattern
    rowColSm: 12,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 3,
    sectionHeading: true,
    sectionHeadingTitle: 'Upcoming talks',
    priorityStatus: true,
    footLinkTitle: '',
    gridItemName: 'channel-talk-item',
    dataBddTitle: 'talks-title-channel',
    dataBddDesc: 'talks-card-description-channel',
    talksUtm:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=upcoming ',
  };

  const recordedChannelTalksComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 3,
    sectionHeading: true,
    sectionHeadingTitle: 'On-demand talks',
    priorityStatus: true,
    footLinkTitle: '',
    gridItemName: 'channel-talk-item',
    dataBddTitle: 'talks-title-summits',
    dataBddDesc: 'talks-card-description-summits',
    talksUtm:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=recorded ',
  };

  const paramsToFetchRelatedSeries = {
    page: 1,
    pageSize: 3,
    channelId: params?.channelId, // '7335'
  };
  const relatedSeriesComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 4,
    gridItemName: 'related-series-card',
    sectionHeading: true,
    sectionHeadingTitle: 'Series',
    priorityStatus: true,
    seriesUrl,
    seriesUTM:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=series-grid',
    footLinkTitle: 'Watch series',
    dataBddTitle: 'related-series-card-title',
    dataBddDesc: 'related-series-card-desc',
    sectionHeadingBddTitle: 'related-series-grid-heading',
  };

  return (
    <>
      {FetchFeaturedChannelTalk(
        paramsToFetchDataFeaturedTalk,
        featuredChannelTalkComponentInfo
      )}
      
      {/* 
         FetchChannelTalksByStatusUpcoming is a temp solution to load a large block of upcoming channel talks
         due to the endpoint unable to provide a sortOrdering closestToNow
         We should revert this to FetchChannelTalksByStatuses when new endpoint built.
         */}
      {FetchChannelTalksByStatusUpcoming(
        hostUrl,
        paramsToFetchDataChannelTalksUpcoming('upcoming,live'),
        upcomingChannelTalksComponentInfo
      )}

      {FetchChannelTalksByStatuses(
        hostUrl,
        paramsToFetchDataChannelTalks('recorded'),
        recordedChannelTalksComponentInfo
      )}

      {FetchSeriesRelated(
        hostUrl,
        paramsToFetchRelatedSeries,
        relatedSeriesComponentInfo
      )}
    </>
  );
};
export default SingleChannelAll;
