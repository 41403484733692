import React, { FC, useState, useEffect } from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import classnames from 'classnames';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
  faXmark,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons';
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
// Context
import { useChannelContext } from '../../context/ChannelContext';
import { useTalksFilters } from '../../context/TalksFilters';
import swrUrlParser from '../../libs/swrUrlParser/swrUrlParser';
import useSWRFetcher from '../../libs/useSWRFetcher';
import useSWR from 'swr';
import OptionsSelect from '@bt-react/components/OptionsSelect';

interface TalksFilterProps {
  handleSearchFilterOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TalksFilterLinks: FC<TalksFilterProps> = ({
  handleSearchFilterOnChange,
}) => {
  const {
    talksSearchFilter,
    setTalksSearchFilter,
    talksCat,
    setTalksCat,
    featuredTalk,
  } = useTalksFilters();
  // Fetch the channel context data

  const { channelData } = useChannelContext();
  const channelId = channelData.id;

  // Params to fetch ChannelV2Feed
  const params = {
    channelId: channelId,
    featuredTalk: featuredTalk,
  };

  const fullUrl = swrUrlParser('/api/channelCategoryList', params);

  const { data: categoryData } = useSWR(fullUrl, useSWRFetcher());

  // fetch the cat terms from the channelData
  // map over categories & split the term object on '/'
  const categoryTerms = categoryData?.categoryList.map((category) => {
    const [mainCategory, subCategory] = category.split('/');
    return { mainCategory, subCategory };
  });

  // this tracks whether the mainCategory has been set once already, to avoid setting multiple times
  const mainCategoryDisplayed = new Set();

  const [isCategoriesVisible, setCategoriesVisible] = useState(false);
  // The small mobile version for replicated select dropdown block
  const [isCategoriesVisibleSelect, setCategoriesVisibleSelect] =
    useState(false);

  // needed to ensure the first Filter select selection is added to the setTalksCat array
  useEffect(() => {}, [talksCat]);

  // Find any activeLinks that are true, use result to determin if we show the reset-block link.
  // also check if the talksSearchFilter has a length to also set the reset link to display if searchbar typed into.
  const anyActiveLinks =
    Object.values(talksCat).some((value) => value) ||
    talksSearchFilter.trim() !== '';

  // The click event handler for filter links
  // passes in the string name of the link & sets it in a state array.
  // Click event also handles setting an activeLink class for styling
  const filtersCallback = (e) => {
    let updatedTalksCat = [...talksCat, e.target.id];
    setTalksCat(updatedTalksCat);

    // Check the talksCat to see if the cat is already added to context array
    // if it is then we want to remove it.
    // else we add/spread it to the talksCat context.
    if (Array.isArray(talksCat) && talksCat.length > 0) {
      if (talksCat.includes(e.target.id)) {
        updatedTalksCat = talksCat.filter((link) => link !== e.target.id);
        setTalksCat(updatedTalksCat);
      } else {
        // if not in the array spread the catLink string into the talksCat array
        updatedTalksCat = [...talksCat, e.target.id];
        setTalksCat(updatedTalksCat);
      }
    }
  };

  const handleFilterSelect = (e) => {
    const updatedTalksCat = [...talksCat, e.value];
    setTalksCat(updatedTalksCat);

    if (Array.isArray(talksCat) && talksCat.length > 0) {
      if (talksCat.includes(e.value)) {
        const updatedTalksCat = talksCat.filter((link) => link !== e.value);
        setTalksCat(updatedTalksCat);
      } else {
        // if not in the array spread the catLink string into the talksCat array
        const updatedTalksCat = [...talksCat, e.value];
        setTalksCat(updatedTalksCat);
      }
    }
  };

  // Clear all set context values if the reset link is clicked
  const handleResetFilters = () => {
    setTalksCat([]); // Reset the talks categories
    setTalksSearchFilter(''); // reset the search field
  };

  return (
    <div className={styles['talks-filter-block']}>
      {/* Search filter block and used talksSearchFilter context as a value */}
      <div className={styles['mobile-search-filter-dropdown']}>
        <div className={styles['search-box-wrapper']}>
          <input
            value={talksSearchFilter}
            type="text"
            id="search-box"
            placeholder={'Search'}
            className={styles['search-box']}
            onChange={(e) => handleSearchFilterOnChange(e)}
          />
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
        <div className={styles['mobile-buttons-wrap']}>
          <button
            className={classnames(
              styles['bt-button-small-bright-short'],
              styles['svg'],
              styles['bt-fluid'],
              styles['mobile-filters-toggle']
            )}
            onClick={() => setCategoriesVisible(!isCategoriesVisible)}
          >
            <FontAwesomeIcon icon={faSliders} />
            Filter by
          </button>

          <button
            className={classnames(
              styles['bt-button-small-bright-short'],
              styles['svg'],
              styles['bt-fluid'],
              styles['mobile-filters-toggle-select']
            )}
            onClick={() =>
              setCategoriesVisibleSelect(!isCategoriesVisibleSelect)
            }
          >
            <FontAwesomeIcon icon={faSliders} />
            Filter by
          </button>

          {anyActiveLinks && (
            <button
              className={classnames(
                styles['bt-button-small-bright-short'],
                styles['svg'],
                styles['bt-fluid'],
                styles['reset-mobile-filters']
              )}
              onClick={handleResetFilters}
            >
              <FontAwesomeIcon icon={faRotateRight} />
              Reset
            </button>
          )}
        </div>
      </div>

      <div className={styles['filter-title-block']}>
        <h3 className={styles['filters-heading']}>Filter by</h3>

        {anyActiveLinks && (
          <p className={styles['reset-filters']}>
            <FontAwesomeIcon icon={faRotateRight} />
            <button
              className={classnames(
                styles['text-link-secondary'],
                styles['remove-btn-styles'],
                styles['med'],
                styles['reset-desktop-filters']
              )}
              onClick={handleResetFilters}
            >
              Reset filters
            </button>
          </p>
        )}
      </div>

      {/* this block handles the desktop version of the filter links */}
      {categoryTerms && !isCategoriesVisible && !isCategoriesVisibleSelect && (
        <div
          className={classnames(styles['categories-parent-wrap'], {
            [styles['show-cats']]: isCategoriesVisible,
          })}
        >
          <>
            {categoryTerms.map(({ mainCategory, subCategory }, index) => {
              // Check if the mainCategory has already been displayed
              // & don't render it a second time
              if (!mainCategoryDisplayed.has(mainCategory)) {
                mainCategoryDisplayed.add(mainCategory);

                //Subcategories gives us a count of all cats in this loop instance
                // of the mainCategory
                const subCategories = categoryTerms.filter(
                  (term) => term.mainCategory === mainCategory
                );

                return (
                  <React.Fragment key={index}>
                    <div className={styles['category-block-wrap']}>
                      <div className={styles['category-title']}>
                        {mainCategory}
                      </div>
                      {/* Loop out ALL the subCategories  */}
                      {categoryTerms
                        .filter((term) => term.mainCategory === mainCategory)
                        .map((term, subIndex) => (
                          <div
                            key={`${index}-${subIndex}`}
                            className={classnames(styles['category-name'], {
                              [styles['last-item']]:
                                subIndex === subCategories.length - 1,
                            })}
                          >
                            <button
                              className={classnames(
                                styles['text-link-secondary'],
                                styles['remove-btn-styles'],
                                styles['med'],
                                {
                                  [styles['active']]: talksCat.includes(
                                    `${mainCategory.toLowerCase()}/${term.subCategory.toLowerCase()}`
                                  ),
                                }
                              )}
                              id={`${mainCategory.toLowerCase()}/${term.subCategory.toLowerCase()}`}
                              onClick={filtersCallback}
                            >
                              {term.subCategory}
                              {talksCat.includes(
                                `${mainCategory.toLowerCase()}/${term.subCategory.toLowerCase()}`
                              ) && (
                                <span>
                                  {' '}
                                  <FontAwesomeIcon icon={faXmark} />
                                </span>
                              )}
                            </button>
                          </div>
                        ))}
                    </div>
                  </React.Fragment>
                );
              }
              return null; // don't display the mainCategory again if it's been rendered once
            })}
          </>
        </div>
      )}

      {/* this replicated block handles the display for tablet view filter links */}
      {categoryTerms && isCategoriesVisible && (
        <div
          className={classnames(styles['categories-parent-wrap'], {
            [styles['show-cats']]: isCategoriesVisible,
          })}
        >
          <>
            {categoryTerms.map(({ mainCategory, subCategory }, index) => {
              // Check if the mainCategory has already been displayed
              // & don't render it a second time
              if (!mainCategoryDisplayed.has(mainCategory)) {
                mainCategoryDisplayed.add(mainCategory);

                //Subcategories gives us a count of all cats in this loop instance
                // of the mainCategory
                const subCategories = categoryTerms.filter(
                  (term) => term.mainCategory === mainCategory
                );

                return (
                  <React.Fragment key={index}>
                    <div className={styles['category-block-wrap']}>
                      <div className={styles['category-title']}>
                        {mainCategory}
                      </div>
                      {/* Loop out ALL the subCategories  */}
                      {categoryTerms
                        .filter((term) => term.mainCategory === mainCategory)
                        .map((term, subIndex) => (
                          <div
                            key={`${index}-${subIndex}`}
                            className={classnames(styles['category-name'], {
                              [styles['last-item']]:
                                subIndex === subCategories.length - 1,
                            })}
                          >
                            <button
                              className={classnames(
                                styles['text-link-secondary'],
                                styles['remove-btn-styles'],
                                styles['med'],
                                {
                                  [styles['active']]: talksCat.includes(
                                    `${mainCategory.toLowerCase()}/${term.subCategory.toLowerCase()}`
                                  ),
                                }
                              )}
                              id={`${mainCategory.toLowerCase()}/${term.subCategory.toLowerCase()}`}
                              onClick={filtersCallback}
                            >
                              {term.subCategory}
                              {talksCat.includes(
                                `${mainCategory.toLowerCase()}/${term.subCategory.toLowerCase()}`
                              ) && (
                                <span>
                                  {' '}
                                  <FontAwesomeIcon icon={faXmark} />
                                </span>
                              )}
                            </button>
                          </div>
                        ))}
                    </div>
                  </React.Fragment>
                );
              }
              return null; // don't display the mainCategory again if it's been rendered once
            })}
          </>
        </div>
      )}

      {/* this replicated block handles the bootstrap  select efect for small mobile display */}
      {categoryTerms && isCategoriesVisibleSelect && (
        <div
          className={classnames(
            styles['categories-parent-wrap'],
            { [styles['show-cats-select']]: isCategoriesVisibleSelect },
            styles['categories-select-wrap']
          )}
        >
          <>
            {categoryTerms.map(({ mainCategory, subCategory }, index) => {
              // Check if the mainCategory has already been displayed
              // & don't render it a second time
              if (!mainCategoryDisplayed.has(mainCategory)) {
                mainCategoryDisplayed.add(mainCategory);

                const subCategories = categoryTerms
                  .filter((term) => term.mainCategory === mainCategory)
                  .map((term) => term.subCategory);

                // the OptionsSelect requires the options prop passed as an array
                const subCategoriesArray = {
                  options: subCategories.map((term) => {
                    return {
                      label: term,
                      value: `${mainCategory.toLowerCase()}/${term.toLowerCase()}`,
                    };
                  }),
                };

                const isTalksCatExists = talksCat.some((item) =>
                  item.startsWith(`${mainCategory.toLowerCase()}`)
                );
                return (
                  <React.Fragment key={index}>
                    <div
                      className={classnames(
                        styles['category-block-wrap'],
                        'form-floatingk'
                      )}
                    >
                      {/* Loop out ALL the subCategories  */}

                      <>
                        <div
                          key={`${index}-${subCategory}`}
                          className={styles['filter-row']}
                        >
                          <OptionsSelect
                            options={subCategoriesArray.options}
                            value={
                              isTalksCatExists
                                ? subCategoriesArray.options.value
                                : ''
                            }
                            inputId={subCategoriesArray.options.value}
                            onChange={handleFilterSelect}
                            placeholder={mainCategory}
                            className={styles['option-select']}
                            floatingLabel={true}
                            isSearchable={false}
                          />
                        </div>
                      </>
                    </div>
                  </React.Fragment>
                );
              }
              return null; // don't display the mainCategory again if it's been rendered once
            })}
          </>
        </div>
      )}
    </div>
  );
};

export default TalksFilterLinks;
