import useSWR from 'swr';

// Components
import SkeletonTalksGridItems from '../../components/listGridItems/TalksGridItems/Skeleton';
import ChannelV2FeedGrid from '../../components/ChannelV2FeedGrid/ChannelV2FeedGrid';
import FilterNoResults from '../../components/SingleChannelBlocks/FilterNoResults';

// Libraries
import removeDuplications from '../removeDuplications/removeDuplications';
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';
import { useTalksFilters } from '../../context/TalksFilters';

// channelV2Feed API URL.
const channelV2FeedAPIUrl = '/api/channelV2Feed';

export const FetchChannelV2Feed = (
  baseUrl,
  params,
  componentInfo,
  state = true
) => {
  const fullUrl = swrUrlParser(channelV2FeedAPIUrl, params);

  const { data, error } = useSWR(state ? fullUrl : null, useSWRFetcher());
  const { featuredTalk } = useTalksFilters();

  if (error) return <></>;

  let talksData;

  if (!data || data?.channelV2Feed?.length === 0) {
    talksData = {
      talks: [],
      found: 0,
    };
  } else {
    // Filter dupes talks from the channelV2Feed list.
    const talkCommunications = removeDuplications(data?.channelV2Feed).filter(
      (talk) => talk?.communication?.id != parseInt(featuredTalk)
    );

    if (!talkCommunications) {
      talksData = {
        talks: [],
        found: 0,
      };
    } else {
      talksData = {
        talks: talkCommunications,
        found: data?.found,
      };
    }
  }

  return (
    <>
      {(() => {
        if (!data) {
          return (
            <SkeletonTalksGridItems
              rowColSm={componentInfo?.rowColSm}
              rowColMd={componentInfo?.rowColMd}
              rowColLg={componentInfo?.rowColLg}
              rowColXl={componentInfo?.rowColXl}
            />
          );
        } else if (talksData['talks']?.length > 0) {
          return (
            <ChannelV2FeedGrid
              footLinkTitle={''}
              channelV2FeedData={talksData['talks']}
              foundTalks={talksData['found']}
              gridItemName={componentInfo?.gridItemName}
              rowColSm={componentInfo?.rowColSm}
              rowColMd={componentInfo?.rowColMd}
              rowColLg={componentInfo?.rowColLg}
              rowColXl={componentInfo?.rowColXl}
              cardLayout={componentInfo?.cardLayout}
              companyLogo={componentInfo?.companyLogo}
              utmParams={componentInfo?.talksUtm}
              talksFilterParams={params}
              baseUrl={baseUrl}
              setLiveLabel={componentInfo?.liveStatus}
              sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
              headingBlockName={componentInfo?.headingBlockName}
              sectionHeading={componentInfo?.sectionHeading}
              sectionHeadingDescription={
                componentInfo?.sectionHeadingDescription
              } // set as empty to disable description
              sectionHeadingLink={componentInfo?.sectionHeadingLink}
              sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
              sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
              sectionHeadingBddDesc={componentInfo?.sectionHeadingBddDesc}
              sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
              linkNewTab={componentInfo?.linkNewTab}
              listLayout={componentInfo?.listLayout}
              priorityStatus={componentInfo?.priorityStatus}
              imgHoverOverlay={componentInfo?.imgHoverOverlay}
              certificateLink={componentInfo?.certificateLink}

              // set a class for the first grid to remove margin top
              //{...(isFirstBlock == true && { classes: 'no-margin-top' })}
            />
          );
        } else if (talksData['talks']?.length < 1) {
          return <FilterNoResults />;
        }
      })()}
    </>
  );
};

export default FetchChannelV2Feed;
