import React, { useState, useEffect } from 'react';
import { useParams } from 'next/navigation';
import sanitize from '@bt-react/libs/sanitize';

// Components
import MetaHead from '../../../components/MetaHead/MetaHead';
import ChannelHeroBanner from '../../../components/HeroBanner/ChannelHeroBanner';
import TopicLinks from '../../../components/TopicLinks/TopicLinks';
import ChannelHeroBannerSkeleton from '../../../components/HeroBanner/ChannelHeroBannerSkeleton';
import PageTabs from '../../../components/PageTabs/PageTabs';
import ModalReg from '../../../components/ModalReg/ModalReg';

// Import the page blocks for single channel
import SingleChannelAll from '../../../components/SingleChannelBlocks/SingleChannelAll';
import SingleChannelTalks from '../../../components/SingleChannelBlocks/SingleChannelTalks';
import SingleChannelSeries from '../../../components/SingleChannelBlocks/SingleChannelSeries';
import SingleChannelVirtualEvents from '../../../components/SingleChannelBlocks/SingleChannelVirtualEvents';

// Libraries
import getBaseUrl from '../../../libs/getBaseUrl';
import nextCookies from 'next-cookies';
import fetchUserProfile from '../../../libs/fetchUserProfile';
import isUserChannelOwner from '../../../libs/FetchIsChannelOwner/FetchIsChannelOwner';

// Services
import channelProfileData from '../../../services/channelProfile/channelProfile';
import channelSummits from '../../../services/channelSummits';

// Context
import { useChannelContext } from '../../../context/ChannelContext';
import { useUserProfileContext } from '../../../context/UserProfileContext';

export default function SingleChannel({
  hostUrl,
  staticAssetUrl,
  seriesUrl,
  userProfileResponse,
  userIsChannelOwner,
  profileResponse,
  seriesCount,
  summitUrl,
  virtualEventCount,
  summitsCount,
}) {
  const {
    setChannelData,
    setUserIsChannelOwner,
    setIsManager,
    setChannelVisibility,
    setSeriesCount,
  } = useChannelContext();

  const { setUserProfile } = useUserProfileContext();

  // Set our channel context with data
  useEffect(() => {
    setChannelData(profileResponse);
    setUserProfile(userProfileResponse);
    setUserIsChannelOwner(userIsChannelOwner);
    setIsManager(userProfileResponse?.isManager);
    setChannelVisibility(profileResponse?.searchVisibility);
    setSeriesCount(seriesCount);
  }, []);

  type Params = { channelId: string };
  const params = useParams<Params>();

  // Build our array of tab link names here.
  // We can test for tab visibility here by using seriesCount  or virtual eventCount
  // to add the name to the array
  // if we have series/VE data spread it into the arry else [] is empty value & adds nothing
  const tabItems = [
    'All',
    'Talks',
    ...(seriesCount > 0 ? ['Series'] : []),
    ...(virtualEventCount > 0 || summitsCount > 0 ? ['Virtual Events'] : []),
  ];

  const [activePage, setActivePage] = useState('All');
  const [activeTab, setActiveTab] = useState('All');
  const handleTabClick = (tab: string) => {

    switch (tab) {
      case 'Talks':
        if (activePage !== 'Talks') setActivePage('Talks');
        setActiveTab('Talks');

        break;
      case 'Series':
        if (activePage !== 'Series') setActivePage('Series');
        setActiveTab('Series');

        break;
      case 'Virtual Events':
        if (activePage !== 'Virtual Events') setActivePage('Virtual Events');
        setActiveTab('Virtual Events');

        break;
      default:
        setActivePage('All');
        setActiveTab('All');
        break;
    }
  };

  // If this is Stage we don't want gogle indexing channels or
  // if this is a channel with it's searchVisibility key set to one of `exclude`
  // it should be removed from google indexing as but allowed to 'follow' links.
  let robotsIndex = 'index, follow';
  if (hostUrl === 'https://www.stage.brighttalk.net') {
    robotsIndex = 'noindex, nofollow';
  }

  if (profileResponse?.searchVisibility === 'excluded') {
    robotsIndex = 'noindex, follow';
  }

  return (
    <>
      <MetaHead
        title={`${profileResponse?.title} - BrightTALK`}
        pageName="Channel"
        hostUrl={hostUrl}
        staticAssetUrl={staticAssetUrl}
        robots={robotsIndex}
      />

      {(() => {
        if (!profileResponse) {
          return <ChannelHeroBannerSkeleton />;
        } else {
          return (
            <ChannelHeroBanner
              pageType="single-channel-banner"
              theme={'dark'}
              hostUrl={hostUrl}
            />
          );
        }
      })()}

      <div className={'single-channel-content'}>
        <PageTabs
          // set the tabs button labels & used for handler switch statement.
          // Button array may be extended for additional items.
          tabs={tabItems}
          onClick={handleTabClick}
          currentTab={activeTab}
        />

        {activePage === 'All' && (
          <SingleChannelAll hostUrl={hostUrl} seriesUrl={seriesUrl} />
        )}

        {activePage === 'Talks' && <SingleChannelTalks hostUrl={hostUrl} />}

        {activePage === 'Series' && (
          <SingleChannelSeries
            channelId={params?.channelId}
            hostUrl={hostUrl}
            seriesUrl={seriesUrl}
          />
        )}
        {activePage === 'Virtual Events' && (
          <SingleChannelVirtualEvents
            channelId={params?.channelId}
            hostUrl={hostUrl}
            summitUrl={summitUrl}
          />
        )}

        {profileResponse && activePage !== 'Talks' && (
          <TopicLinks topicsKeyWords={profileResponse?.keywordsList} />
        )}
      </div>
      <ModalReg hostUrl={hostUrl} />
    </>
  );
}
// This gets called on every request
export async function getServerSideProps(context) {
  const { BTSESSION } = nextCookies(context);
  const channelId = context.params['channelId'];

  const userIsChannelOwner = await isUserChannelOwner(
    channelId,
    encodeURIComponent(BTSESSION)
  );

  const userProfileResponse = await fetchUserProfile(
    encodeURIComponent(BTSESSION)
  );

  // The channel details (profile) used for the hero banner and for keywords passed
  // to the topics component
  const profileResponse = await channelProfileData(channelId);

  // call channelSummits API to fetch the series count to display summits series
  const seriesParams = {
    channelId: channelId,
    type: 'series',
  };
  // Get series response to get the count.
  const seriesResponse = await channelSummits(seriesParams);
  const seriesCount = seriesResponse ? seriesResponse['total'] : '';

  // call channelSummits API to fetch the summits count to display summits tab
  const summitsParams = {
    channelId: channelId,
    type: 'summits',
  };
  // Get summits response to get the count.
  const summitsResponse = await channelSummits(summitsParams);
  const summitsCount = summitsResponse ? summitsResponse['total'] : '';

  // call channelSummits API to fetch the virtual events count to display virtual events tab
  const virtualEventParams = {
    channelId: channelId,
    type: 'virtualEvents',
  };
  // Get summits response to get the count.
  const virtualEventResponse = await channelSummits(virtualEventParams);
  const virtualEventCount = virtualEventResponse
    ? virtualEventResponse['total']
    : 0;

  // If we do not get the response redirect to the search page.
  if (profileResponse?.code === 'InvalidChannelId') {
    return {
      redirect: {
        permanent: false,
        destination: '/channel-not-found',
      },
    };
  }

  // Iterate Over userProfileResponse Object Properties
  // Update the values after sanitize data
  Object.keys(userProfileResponse).forEach((key) => {
    if (userProfileResponse[key]) {
      userProfileResponse[key] = sanitize(userProfileResponse[key]);
    }
  });

  // Iterate Over profileResponse Object Properties
  // Update the values after sanitize data
  const sanitizeItemsToIterateThrough = [
    'title',
    'description',
    'organisation',
    'keywords',
    'strapline',
  ];
  sanitizeItemsToIterateThrough.forEach((key) => {
    profileResponse[key] = sanitize(profileResponse[key]);
  });

  return {
    props: {
      baseUrl: getBaseUrl(),
      hostUrl: global.process.env.HOST,
      staticAssetUrl: global.process.env.STATIC_ASSETS_CDN_URL,
      seriesUrl: global.process.env.SERIES_HOST,
      summitUrl: global.process.env.SUMMITS_HOST,
      userIsChannelOwner: userIsChannelOwner,
      userProfileResponse: userProfileResponse,
      profileResponse: profileResponse ? profileResponse : '',
      seriesCount: seriesCount,
      summitsCount: summitsCount,
      virtualEventCount: virtualEventCount,
    },
  };
}
