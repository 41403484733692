import React, { FC, useState } from 'react';
import classnames from 'classnames';
import {
  Container,
  Row,
  Col,
  CardDeck,
  CardGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardFooter,
  Button,
} from 'reactstrap';
import styles from './index.module.scss';
import sanitize from '@bt-react/libs/sanitize';
import { useRouter } from 'next/router';

import Truncate from 'react-truncate';
import Image from 'next/image';

import CardsImage from '../../Cards/CardsImage/CardsImage';
import DateTime from '../../Cards/CardsDateTime/CardsDateTime';
import PDFBlob from '@bt-react/components/PDFBlob';

// Libraries
import gridUTMParams from '../../../libs/gridUTMParams';
import CardsFooterButtonsContainer from '../../CardsFooterButtonsContainer/CardsFooterButtonsContainer';
import WatchLaterButtons from '../../WatchLaterButtons/WatchLaterButtons';
import getTalkImageSrc from '../../../libs/getTalkImageSrc';

interface listGrids {
  gridItemName?: string; // What are all the grid item names?@hugo? Lets change this to string literal type
  listStyle?: string; // Can we change this to string literal type as well.
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  footLinkTitle: string;
  companyLogo?: boolean;
  imgHoverOverlay?: boolean;
  gridData: Communication[];
  flexDirection?:
    | 'flex-row'
    | 'flex-reverse-row'
    | 'flex-reverse-column'
    | 'flex-column';
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  utmParams?: string;
  setLiveLabel?: boolean;
  envUrl: string;
  priorityStatus?: boolean;
  certificateLink?: boolean;
  pageType?: string;
  showTime?: boolean;
}

export interface Communication {
  communication: {
    id: number;
  };
  channel: Channel[];
  title: string;
  summary: string;
  keywords: string;
  presenter: string;
  status: 'live' | 'upcoming' | 'recorded' | 'processing';
  scheduled: Date;
  entryTime: Date;
  closeTime: Date;
  created: Date;
  lastUpdated: Date;
  duration: number;
  start: Date;
  timeZone: string;
  format: string;
  publishStatus: string;
  visibility: string;
  links: Link[];
  url: string;
  channelWebcastUrl: string;
  rating: number;
  totalViewings: number;
  uniqueViewers: number;
  registrations: number;
  ratingCount: number;
  categories?: Categories[];
}

export interface Categories {
  label: string;
  term: string;
}
export interface Channel {
  id: number;
  link: Link[];
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title: string;
}

// This gets called on every request
export async function getServerSideProps(context) {
  // Pass data to the page via props
  return {
    props: {
      envUrl: global.process.env.HOST,
    },
  };
}

const ChannelFilterTalksGridItems: FC<listGrids> = ({
  gridItemName,
  listStyle,
  listLayout,
  cardLayout,
  gridData,
  flexDirection,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  footLinkTitle,
  companyLogo,
  imgHoverOverlay,
  utmParams,
  setLiveLabel,
  envUrl,
  priorityStatus,
  certificateLink,
  pageType,
  showTime = true,
}) => {
  {
    /*
GridData talks keys:
keys {id, channel, title, description, keywords, presenter, status, scheduled,
     entryTime, closeTime, created, lastUpdated, duration, start, timeZone,
     format, publishStatus, visibility, links, url, channelWebcastUrl, rating,
     totalViewings, uniqueViewers, registrations, ratingCount}

 We pass:
 1. We pass in talk status on the col element to identify the talk as upcoing/sheduled or recorded.
 2. talk status to the footer dateTime comp to switch between display formats.
*/
  }

  const Data = gridData;

  function getChannelLogo(channel) {
    let channelLogo = '/portal/placeholders/brand-logo.svg';
    channel &&
      [channel].map(({ link }) => {
        link &&
          [link].map(({ href, title }) => {
            if (title === 'logo') {
              channelLogo = href;
            }
          });
      });
    return channelLogo;
  }

  // set a image hover class to enable switching off styles
  // Only really want to set this class if we DO NOT want hover
  // otherwise we might have pass props for all other grid calls.
  let imgHoverOverlayClass = '';
  if (imgHoverOverlay === false) {
    imgHoverOverlayClass = 'no-img-hover';
  } else if (imgHoverOverlay === true) {
    imgHoverOverlayClass = '';
  }

  {
    /*
  Where we call talks grid but seperate the grids by status we need to be careful
  Not to show live labels  on a upcoming talk in error but ALWAYS show live label on live block
  APF-2078 hotfix.
 */
  }
  function showLiveLabel(status, setLiveLabel) {
    let showLiveLabel = false;

    if (setLiveLabel === false) {
      showLiveLabel = false;
    } else if (setLiveLabel === true) {
      showLiveLabel = true;
    } else if (status === 'live') {
      showLiveLabel = true;
    }
    //console.log(showLiveLabel);
    return showLiveLabel;
  }

  {
    /*
  If we have manually forced display of live labels -as above - we should
  also ensure that the live status class is set as live
  in case status hasn't changed/updated otherwise we won't position element as visible.
 */
  }
  function setStatus(status, setLiveLabel) {
    let setStatus = '';

    if (setLiveLabel === true) {
      setStatus = 'live';
    } else {
      setStatus = status;
    }
    return setStatus;
  }

  const router = useRouter();

  let utmFromUrl;

  utmFromUrl = router.asPath.split('?')?.[1];

  if (utmFromUrl) {
    utmFromUrl = '?' + utmFromUrl;
  }

  return (
    <>
      <Row
        className={classnames(styles[listLayout], 'g-4')}
        data-bdd="talks-grid"
      >
        {Data?.map(
          (
            {
              communication,
              channelWebcastUrl,
              title,
              summary,
              links,
              presenter,
              url,
              status,
              channel,
              start,
              duration,
            },
            index
          ) => (
            <Col
              sm={rowColSm}
              md={rowColMd}
              lg={rowColLg}
              xl={rowColXl}
              className={classnames(
                styles['column']
                // setHideClassForBtn(clickedId, index)
              )}
              id={`TalksColId-` + index}
              key={`TalksCol-` + communication?.id + index}
              data-talk-type={status}
              data-section-name={gridItemName + '-' + index}
            >
              <CardDeck
                key={`CardDeck-` + communication?.id + index}
                className={classnames('h-100')}
              >
                <Card
                  key={`Card-` + communication?.id + index}
                  className={classnames(
                    styles['list-item'],
                    styles[gridItemName],
                    styles[cardLayout],
                    styles[listStyle],
                    styles[setStatus(status, setLiveLabel)],
                    'h-100',
                    styles[imgHoverOverlayClass],
                    flexDirection
                  )}
                >
                  <a
                    className={classnames(
                      'stretched-link',
                      styles['link-img-hover']
                    )}
                    key={`pop-talks-item` + communication?.id + index}
                    href={
                      envUrl +
                      '/webcast/' +
                      channel['id'] +
                      '/' +
                      communication?.id +
                      gridUTMParams(utmFromUrl !== undefined ? utmFromUrl : utmParams + status, gridItemName, index + 1)
                    }
                    aria-label={'View ' + title}
                  ></a>

                  <div
                    key={`img-wrap` + communication?.id + index}
                    className={styles['img-wrap']}
                  >
                    {showLiveLabel(status, setLiveLabel) && (
                      <span className={styles['live-label']}>LIVE NOW</span>
                    )}

                    <CardsImage
                      key={`CardsImage-` + communication?.id + index}
                      id={communication?.id}
                      href={getTalkImageSrc(links)}
                      title={title}
                      gridType={'webcasts'}
                      priorityStatus={priorityStatus}
                    />
                  </div>

                  <CardBody
                    key={`cardbody-` + communication?.id + index}
                    className={styles['cardbody']}
                  >
                    {companyLogo == true &&
                      (gridItemName === 'pop-talks-item' ||
                        gridItemName === 'talks-grid-knowledge-feed') && (
                        <div
                          key={`companyLogo` + communication?.id + index}
                          className={styles['body-comp-logo']}
                        >
                          <Image
                            key={`channelLogo-` + communication?.id + index}
                            src={getChannelLogo(channel)}
                            quality={80}
                            alt={'Channel Logo'}
                            width="30"
                            height="30"
                          />
                        </div>
                      )}

                    <div
                      key={`main-body` + communication?.id + index}
                      className={styles['main-body']}
                    >
                      <CardTitle
                        tag="h2"
                        className={styles['item-title']}
                        key={`item-title` + communication?.id + index}
                        data-bdd={'talks-title'}
                      >
                        <Truncate
                          key={`Truncate` + communication?.id + index}
                          lines={2}
                          title={sanitize(title)}
                        >
                          {sanitize(title)}
                        </Truncate>
                      </CardTitle>

                      {gridItemName === 'talks-grid-search' && (
                        <CardText className={classnames(styles['presenter'])}>
                          {companyLogo == true && (
                            <div
                              key={`companyLogo` + communication?.id + index}
                              className={styles['body-comp-logo']}
                            >
                              <Image
                                key={`channelLogo-` + communication?.id + index}
                                src={getChannelLogo(channel)}
                                quality={80}
                                alt={'Channel Logo'}
                                width={30}
                                height={30}
                              />
                            </div>
                          )}

                          <span className={styles['presenter-span']}>
                            {presenter}
                          </span>
                        </CardText>
                      )}

                      <CardText
                        tag="p"
                        className={classnames(styles['card-description'])}
                        key={`card-description` + communication?.id + index}
                        data-bdd={'talks-card-description'}
                      >
                        <Truncate
                          key={`Truncate` + communication?.id + index}
                          lines={3}
                          title={sanitize(summary)}
                        >
                          {sanitize(summary)}
                        </Truncate>
                      </CardText>

                      <CardText
                        key={`item-meta` + communication?.id + index}
                        tag="p"
                        className={styles['item-meta']}
                      >
                        <DateTime
                          key={`DateTime` + communication?.id + index}
                          id={`DateTime-` + communication?.id}
                          status={status}
                          dateTime={start.valueOf() * 1000}
                          showTime={showTime}
                          fromType="channel-filter"
                          duration={duration}
                        />

                        {footLinkTitle != '' && (
                          <a
                            href={url}
                            key={`footLinkTitle` + communication?.id + index}
                          >
                            {footLinkTitle}
                          </a>
                        )}
                      </CardText>
                    </div>
                  </CardBody>
                </Card>
              </CardDeck>
            </Col>
          )
        )}
      </Row>
    </>
  );
};

export default ChannelFilterTalksGridItems;
