import swrUrlParser from '../swrUrlParser/swrUrlParser';
import handleErrorResponse from '../handleErrorResponse';

async function postFetch(
  url: string,
  params = {},
  body: string,
  token?: string,
  cache: number = 3600,
  BTSESSION?: string
) {
  const fullUrl = swrUrlParser(url, params);

  let cacheTime;
  // Turn off cache for test01 debugging
  if (url.includes('test01')) {
    cacheTime = 0;
  } else {
    cacheTime = cache;
  }

  // set header as a const to allow cookie value to be set conditionaly.
  // We may have use cases for settings params but not having cookie authentication required.
  const headers = {
    Accept: 'application/json',
    'X-BrightTALK-access': 'niHeeF1ao2',
    'BrightTALK-API-Client': 'audience-portal-wordpress',
    'Content-Type': 'application/json',
    ...(token && { Authorization: token }),
    ...(BTSESSION && { Cookie: 'BTSESSION=' + BTSESSION }),
  };

  let response;

  try {
    // Set options const to allow setting body  conditionally.
    // this allows use cases where there may not be body data to set.
    const options = {
      method: 'POST',
      headers: headers,
      next: { revalidate: cacheTime },
      ...(body && { body: body }),
    };

    response = await fetch(fullUrl, options);

    response = await response.json();
  } catch (err) {
    handleErrorResponse(fullUrl, err);
  }

  return response;
}

export default postFetch;
