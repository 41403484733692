import React, { FC, useState, useEffect } from 'react';
import classnames from 'classnames';

// Components
import SectionHeadings from '../SectionHeadings/SectionHeadings';
import LoadMore from '../LoadMore/LoadMore';
import ChannelFilterTalksGridItems from '../listGridItems/TalksGridItems/ChannelFilterTalksGridItems';
// Context
import { useTalksFilters } from '../../context/TalksFilters';
// Libs
import FilterNoResults from '../SingleChannelBlocks/FilterNoResults';
import filterChannelList from '../../libs/filterChannelList';

export interface TalksProps {
  channelV2FeedData: Communication[];
  foundTalks: number;
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  footLinkTitle?: string;
  gridItemName?: string;
  companyLogo: boolean;
  imgHoverOverlay?: boolean;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  utmParams?: string;
  talksFilterParams?: TalksFilterParamsArray;
  baseUrl: string;
  setLiveLabel?: boolean;
  priorityStatus?: boolean;
  headingBlockName?: string;
  sectionHeading: true | false;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  skeletonHeadingDescription?: boolean;
  isFirstBlock?: boolean;
  linkNewTab?: boolean;
  certificateLink?: boolean;
}

export interface TalksFilterParamsArray {
  size?: number;
  bq?: string;
  rank?: string;
  rankClosest?: string;
}

export interface Communication {
  communication: {
    id: number;
  };
  channel: Channel[];
  title: string;
  summary: string;
  keywords: string;
  presenter: string;
  status: 'live' | 'upcoming' | 'recorded' | 'processing';
  scheduled: Date;
  entryTime: Date;
  closeTime: Date;
  created: Date;
  lastUpdated: Date;
  duration: number;
  start: Date;
  timeZone: string;
  format: string;
  publishStatus: string;
  visibility: string;
  links: Link[];
  url: string;
  channelWebcastUrl: string;
  rating: number;
  totalViewings: number;
  uniqueViewers: number;
  registrations: number;
  ratingCount: number;
}

export interface Channel {
  id: number;
  link: Link[];
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title: string;
}

const ChannelV2FeedGrid: FC<TalksProps> = ({
  channelV2FeedData,
  foundTalks,
  listLayout,
  cardLayout,
  footLinkTitle,
  gridItemName,
  companyLogo,
  imgHoverOverlay,
  pageType,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  utmParams = '',
  talksFilterParams,
  baseUrl,
  setLiveLabel,
  priorityStatus,
  headingBlockName,
  sectionHeading,
  sectionHeadingTitle,
  sectionHeadingDescription,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  skeletonHeadingDescription,
  linkNewTab,
  isFirstBlock,
  certificateLink,
}) => {
  const { talksCat, talksSearchFilter } = useTalksFilters();
  const [visible, setVisible] = useState(12);
  const [showLoadMoreSpinner, setShowLoadMoreSpinner] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [talksData, setTalksData] = useState({
    originalTalks: channelV2FeedData,
    filteredTalks: channelV2FeedData.slice(0, 12),
  });
  const [showLoadMore, setShowLoadMore] = useState(false);

  // Set the talk data state with updated filtered data.
  // Used talksCat, talksSearchFilter as a dependency (Whenever the talksCat, talksSearchFilter gets updated trigger the useEffect to get the filtered data)
  useEffect(() => {
    if (talksCat?.length > 0 || talksSearchFilter) {
      let slicedListItems;

      // Call the filterChannelList to get the filtered talks on search filter change.
      const filteredData = filterChannelList(
        channelV2FeedData,
        talksSearchFilter,
        talksCat
      );

      // Slice the filtered talks
      if (filteredData?.length) slicedListItems = filteredData.slice(0, 12);

      const finalList = filteredData?.length
        ? filteredData
        : talksCat?.length || talksSearchFilter
        ? []
        : channelV2FeedData;

      // Set the ShowResetButton to display the "No results found"
      setShowResetButton(finalList?.length ? false : true);

      // Set the talk data
      setTalksData({
        originalTalks: finalList,
        filteredTalks: finalList?.length ? slicedListItems : [],
      });

      setShowLoadMore(
        finalList?.length <= 12 || 12 >= finalList?.length ? false : true
      );
      // Set visible to default value which is 12.
      setVisible(12);
    } else {
      setTalksData({
        originalTalks: channelV2FeedData,
        filteredTalks: channelV2FeedData.slice(0, 12),
      });
      setShowLoadMore(channelV2FeedData?.length <= visible ? false : true);
    }
  }, [talksCat, talksSearchFilter]);

  const loadMorePage = async () => {
    // Show the spinner.
    setShowLoadMoreSpinner(true);
    // Set the visible
    setVisible((prevValue) => prevValue + 12);
    // Get the start index.
    const startIndex = visible;
    // Get the end index.
    const endIndex = startIndex + 12;
    // Slice the items for the load more.
    const loadMoreItems = talksData?.originalTalks.slice(startIndex, endIndex);
    // Set the talks data.
    setTalksData({
      ...talksData,
      filteredTalks: [...talksData?.filteredTalks, ...loadMoreItems],
    });
    // Turn off loading spinner
    setShowLoadMoreSpinner(false);
    // Hide the load more.
    setShowLoadMore(
      talksData?.originalTalks?.length <= visible + 12 ? false : true
    );
  };

  return (
    <div
      className={classnames('latest-talk-block', 'grid-box')}
      data-bdd="talks-grid"
    >
      {(() => {
        if (sectionHeading == true) {
          return (
            <SectionHeadings
              title={sectionHeadingTitle}
              description={sectionHeadingDescription}
              link={sectionHeadingLink}
              linkTitle={sectionHeadingLinkText}
              headingBlockName={headingBlockName}
              dataBddTitle={sectionHeadingBddTitle}
              dataBddDesc={sectionHeadingBddDesc}
              dataBddLink={sectionHeadingBddLink}
              // set a class for the first grid to remove margin top
              {...(isFirstBlock == true && { classes: 'no-margin-top' })}
            />
          );
        } else {
          <></>;
        }
      })()}

      {(() => {
        if (talksData?.filteredTalks?.length > 0) {
          return (
            <>
              <ChannelFilterTalksGridItems
                gridData={talksData?.filteredTalks}
                footLinkTitle={footLinkTitle}
                gridItemName={gridItemName}
                rowColSm={rowColSm}
                rowColMd={rowColMd}
                rowColLg={rowColLg}
                rowColXl={rowColXl}
                listStyle={'card'}
                listLayout={listLayout}
                cardLayout={cardLayout}
                companyLogo={companyLogo}
                imgHoverOverlay={imgHoverOverlay}
                utmParams={utmParams}
                setLiveLabel={setLiveLabel}
                priorityStatus={priorityStatus}
                envUrl={baseUrl}
                certificateLink={certificateLink}
              />

              <LoadMore
                onClick={loadMorePage}
                loader={showLoadMoreSpinner}
                showLoadMore={showLoadMore}
                bddLoadmoreBlock={'channel-filter-load-more-btn'}
                gridItemName={gridItemName}
              />
            </>
          );
        } else if (showResetButton && talksData?.originalTalks?.length === 0) {
          return (
            <>
              <FilterNoResults />
            </>
          );
        }
      })()}
    </div>
  );
};

export default ChannelV2FeedGrid;
