import FetchFeaturedChannelSeries from '../../libs/FetchFeaturedChannelSeries/FetchFeaturedChannelSeries';
import FetchChannelSeries from '../../libs/FetchChannelSeries/FetchChannelSeries';

export default function SingleChannelSeries({ channelId, hostUrl, seriesUrl }) {
  // Params to fetch the upcoming series for the specific channel.
  const paramsToFetchUpcomingSeries = {
    page: 1,
    pageSize: 6,
    type: 'series',
    status: 'upcoming',
    channelId: channelId,
    sortBy: 'scheduledStartDate',
  };
  // Pass necessary info for the upcoming series component for the specific channel.
  const upcomingSeriesComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 4,
    gridItemName: 'channel-series-card',
    sectionHeading: true,
    sectionHeadingTitle: 'New episodes coming up',
    priorityStatus: true,
    seriesUrl,
    seriesUTM:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=series-grid',
    footLinkTitle: 'Watch series',
    dataBddTitle: 'channel-series-card-title',
    dataBddDesc: 'channel-series-card-desc',
    sectionHeadingBddTitle: 'channel-series-grid-heading',
  };
  // Params to fetch the on-demand series for the specific channel.
  const paramsToFetchOnDemandSeries = {
    page: 1,
    pageSize: 6,
    type: 'series',
    status: 'recorded',
    channelId: channelId,
    sortBy: 'scheduledStartDate',
  };
  // Pass necessary info for the recorded series component for the specific channel.
  const onDemandSeriesComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 4,
    gridItemName: 'related-series-card',
    sectionHeading: true,
    sectionHeadingTitle: 'Catch-up and watch on-demand',
    priorityStatus: true,
    seriesUrl,
    seriesUTM:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=series-grid',
    footLinkTitle: 'Watch series',
    dataBddTitle: 'related-series-card-title',
    dataBddDesc: 'related-series-card-desc',
    sectionHeadingBddTitle: 'related-series-grid-heading',
  };
  // Params to fetch the feature series for the specific channel (Most recent one).
  const paramsToFetchFeaturedSeries = {
    page: 1,
    pageSize: 3,
    type: 'series',
    channelId: channelId,
  };
  // UTM params for featured series.
  const featuredSeriesUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=all-series&utm_content=featured';
  const featuredSeriesComponentInfo = {
    priorityStatus: true,
    seriesUrl,
    seriesUTM: featuredSeriesUTM,
    type: 'series',
    sectionTitle: 'Series',
  };

  return (
    <>
      {FetchFeaturedChannelSeries(
        paramsToFetchFeaturedSeries,
        featuredSeriesComponentInfo,
        hostUrl
      )}
      {FetchChannelSeries(
        hostUrl,
        paramsToFetchUpcomingSeries,
        upcomingSeriesComponentInfo
      )}
      {FetchChannelSeries(
        hostUrl,
        paramsToFetchOnDemandSeries,
        onDemandSeriesComponentInfo
      )}
    </>
  );
}
