import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonSeriesGridItems from '../../components/listGridItems/SeriesGridItems/Skeleton';
import SeriesChannelGrid from '../../components/SeriesChannelGrid/SeriesChannelGrid';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';

// API URL.
const seriesChannelApiUrl = '/api/channelSummits';

export const FetchChannelSeries = (baseUrl, params, componentInfo, state = true) => {
  const fullUrl = swrUrlParser(seriesChannelApiUrl, params);

  const { data, error } = useSWR(state ? fullUrl : null, useSWRFetcher());

  let seriesChannelData;

  if (error) {
    seriesChannelData = {
      series: [],
      found: 0,
    };
  }

  if (!data || data['series']?.length === 0) {
    seriesChannelData = {
      series: [],
      found: 0,
    };
  } else {
    seriesChannelData = {
      series: data['series'],
      found: data['found'],
      links: data['paginateLinks'],
    };
  }

  if (!data) {
    return (
      <>
        <SkeletonSectionHeadings
          skeletonHeadingDescription={false}
          headingBlockName={componentInfo?.sectionHeadingTitle}
        />
        <SkeletonSeriesGridItems />
      </>
    );
  } else if (seriesChannelData['series']?.length > 0) {
    return (
      <>
        <SeriesChannelGrid
          SeriesGrid={seriesChannelData['series']}
          channelId={params?.channelId}
          found={seriesChannelData?.found}
          paginateLinks={seriesChannelData?.links}
          page={params?.page}
          pageSize={params?.pageSize}
          type={'series'}
          filter={params?.status}
          footLinkTitle={componentInfo?.footLinkTitle}
          gridItemName={componentInfo?.gridItemName}
          utmParams={componentInfo?.seriesUTM}
          rowColSm={componentInfo?.rowColSm}
          rowColMd={componentInfo?.rowColMd}
          rowColLg={componentInfo?.rowColLg}
          rowColXl={componentInfo?.rowColXl}
          dataBddTitle={componentInfo?.dataBddTitle}
          dataBddDesc={componentInfo?.dataBddDesc}
          summitUrl={componentInfo?.summitUrl}
          seriesUrl={componentInfo?.seriesUrl}
          baseUrl={baseUrl}
          priorityStatus={componentInfo?.priorityStatus}
          headingBlockName={componentInfo?.headingBlockName}
          sectionHeading={componentInfo?.sectionHeading}
          sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
          sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
          sectionHeadingDescription={componentInfo?.sectionHeadingDescription} // set as empty to disable description
          sectionHeadingLink={componentInfo?.sectionHeadingLink}
          sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
          sectionHeadingBddDesc={componentInfo?.sectionHeadingBddTitle}
          sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
          skeletonHeadingDescription={false}
          isFirstBlock={false}
          listLayout={componentInfo?.listLayout}
          cardLayout={componentInfo?.cardLayout}
          pageType={componentInfo?.pageType}
          clickableCard={componentInfo?.clickableCard}
          sortBy={params?.sortBy}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default FetchChannelSeries;
