import React, { FC } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import styles from './index.module.scss';
import pushTagToDataLayerGA4 from '@bt-react/components/pushTagToDataLayerGA4';

import GlobalAuth from '../../components/GlobalAuth';
import { useSeriesContext } from '../../context/SeriesContext';

export interface ModalProps {
  hostUrl?: string;
}

// registerType is a hardcoded value passed in from the component calling the modal
// 'register-all', register-featured', 'register-single-episode'

const ModalReg: FC<ModalProps> = ({ hostUrl }) => {
  const { modal, setModal, RegisterRequest } = useSeriesContext();
  //console.log(modalRegisterType);

  const toggle = () => {
    setModal(!modal);
    pushTagToDataLayerGA4('click_tt', {
      click_category: 'Series',
      click_action: 'Series ' + RegisterRequest?.type + ' Popup',
      click_subtype: modal ? 'closed' : 'open',
    });
  };

  // Set a custom close button to match designs
  const closeBtn = (
    <>
      <button
        className={classnames(
          styles['close'],
          styles['text-link-secondary'],
          styles['sml']
        )}
        onClick={toggle}
        type="button"
      >
        Close x
      </button>
    </>
  );

  const channelId = RegisterRequest?.data?.channelId;

  return (
    <Modal
      className={styles['modal-box']}
      isOpen={modal}
      centered={true}
      scrollable={true}
    >
      <ModalHeader
        toggle={toggle}
        close={closeBtn}
        className={classnames(styles['modal-close'])}
      ></ModalHeader>
      <ModalBody className={styles['modal-container']}>
        <GlobalAuth
          type="WP-Registration"
          hostUrl={hostUrl}
          redirectUrl=""
          loadingType="bt-modal-form"
          redirect={false}
          passwordLess={true}
          borderless={true}
          channelId={channelId}
        />
      </ModalBody>
    </Modal>
  );
};
export default ModalReg;
