import React from 'react'
import Select, { components } from 'react-select'
import styles, { colours, misc } from '@bt-react/styles'
import { OptionsSelectProps } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import style from './OptionsSelect.module.scss'
import classNames from 'classnames'
import Icon, { IconType } from '../Icon'
import isEqual from 'lodash/isEqual'

const cssVariables = { ...colours, ...misc }

export const Control = (props: any) => {

  const { hasValue, isFocused, menuIsOpen, selectProps } = props

  const isFloating = hasValue ? true : (isFocused && menuIsOpen) ? true : false

  return (
    <>
      <label className={classNames(style['label'], { [style['floating-label']]: isFloating })}>
        {selectProps?.placeholder || 'Select'}
      </label>
      <components.Control {...props} />
    </>
  )
}

export default class OptionsSelect extends React.Component<OptionsSelectProps> {

  getStyles(invalid) {

    const { floatingLabel = false } = this.props

    if (this.props.getStyle) {

      return this.props.getStyle

    } else {

      return {
        singleValue: (defaultStyles) => ({
          ...defaultStyles,
          color: cssVariables.primaryText,
          ...(floatingLabel && { marginLeft: '0px', top: 'unset', transform: 'unset' })
        }),
        valueContainer: (defaultStyles, { hasValue, isFocused }) => {
          const isFloating = hasValue || isFocused
          return {
            ...defaultStyles,
            ...(floatingLabel && { padding: isFloating ? '25px 16px 12px 16px' : '18px 16px' })
          }
        },
        container: (defaultStyles, { hasValue, isFocused }) => {
          const isFloating = hasValue || isFocused
          return {
            ...defaultStyles,
            ...(floatingLabel && { background: (isFloating ? `${cssVariables.background} 0% 0% no-repeat padding-box` : `#F9F9FA 0% 0% no-repeat padding-box`) })
          }
        },
        placeholder: (defaultStyles) => ({
          ...defaultStyles,
          ...(floatingLabel && { display: 'none' })
        }),
        control: (defaultStyles, { isFocused }) => ({
          ...defaultStyles,
          borderRadius: '0',
          backgroundColor: '',
          ':hover': {
            borderColor: invalid ? cssVariables['select-input-invalid-border-color'] : (isFocused ? cssVariables['select-input-focus-border-color'] : cssVariables['select-input-border-color'])
          },
          borderColor: invalid ? cssVariables['select-input-invalid-border-color'] : (isFocused ? cssVariables['select-input-focus-border-color'] : cssVariables['select-input-border-color']),
          boxShadow: isFocused ? (invalid ? cssVariables['select-invalid-input-focusbox-shadow'] : cssVariables['select-input-focusbox-shadow']) : '',
          ...(floatingLabel && {
            border: "0",
            borderBottom: isFocused ? `2px solid ${cssVariables['input-focus-border-color']}` : `1px solid ${cssVariables['tertiary']}`,
            boxShadow: 'none'
          })
        }),
        option: (defaultStyles, { isFocused, isSelected, options, data }) => ({
          ...defaultStyles,
          backgroundColor: isFocused ? cssVariables['select-option-background-focus'] : cssVariables['select-option-background'],
          ':hover': {
            backgroundColor: cssVariables['select-option-background-focus']
          },
          color: isSelected ? cssVariables.primary : 'default',
          border: `1px solid ${cssVariables['select-input-border-color']}`,
          borderTop: '0',
          cursor: 'pointer',
          pointerEvents: this.props.disableSelectedOption && isSelected ? 'none' : 'auto',
          ...(floatingLabel && {
            borderLeft: '0',
            borderRight: '0',
            borderBottom: (isEqual(options[options?.length - 1], data)) ? '0' : `1px solid ${cssVariables['select-input-border-color']}`
          })
        }),
        menu: (defaultStyles, { options }) => ({
          ...defaultStyles,
          borderRadius: '0',
          marginTop: '2px',
          boxShadow: '0',
          paddingTop: '0',
          marginBottom: '0',
          visibility: options.length ? 'visible' : 'hidden',
          zIndex: 999,
          ...(floatingLabel && {
            border: `1px solid ${cssVariables['select-input-border-color']}`,
            borderTop: '0',
            marginTop: '0',
          })
        }),
        menuList: (defaultStyles) => ({
          ...defaultStyles,
          paddingTop: '0',
          paddingBottom: '0',
          marginBottom: '0',
          ...(this.props.menuHeight && this.props.menuHeight !== '' && { maxHeight: this.props.menuHeight })
        }),
        indicatorSeparator: () => ({
          display: 'none'
        })
      }
    }
  }

  //Only For Channels Dropdown in Add Content popup
  DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon id="pencilEdit" width="10px" height="13px" color="#4d4d4d" icon={faCaretDown} />
      </components.DropdownIndicator>
    );
  };

  FloatingLabelDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon name={IconType.iconName.chevronDown} className={style['chevrondown-icon']} />
      </components.DropdownIndicator>
    );
  };

  render() {

    const { floatingLabel = false, error, touched } = this.props

    const invalid = error && touched

    const style = this.props.getStyle ? this.props.getStyle : this.getStyles(invalid)

    return (
      <div ref={this.props.innerRef} className={this.props.className} data-bdd={this.props.dataBdd || 'select'}>
        <Select
          components={this.props.placeholder === 'Channel' ?
            { Option: (props) => (<div data-bdd='option'><components.Option {...props} /></div>), DropdownIndicator: this.DropdownIndicator } :
            { Option: (props) => (<div data-bdd='option'><components.Option {...props} /></div>), ...(floatingLabel && { Control, DropdownIndicator: this.FloatingLabelDropdownIndicator }) }
          }
          className={invalid ? styles['is-invalid'] : ''}
          inputId={this.props.inputId}
          touched={this.props.touched}
          error={this.props.error}
          placeholder={this.props.placeholder}
          isSearchable={this.props.isSearchable}
          options={this.props.options}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          styles={style}
          value={this.props.value}
          isDisabled={this.props.disabled}
          defaultValue={this.props.defaultValue}
          getOptionLabel={option => (this.props.optionLabel) ? option[this.props.optionLabel] : option.label}
          getOptionValue={option => (this.props.optionValue) ? option[this.props.optionValue] : option.value}
          noOptionsMessage={() => (this.props.noOptionsMessage === null || this.props.noOptionsMessage) ? this.props.noOptionsMessage : 'No options'}
          onInputChange={this.props.onInputChange}
          autoFocus={this.props.autoFocus}
          menuPlacement={this.props.menuPlacement}
          isOptionDisabled={(option) => (option.isDisabled || option.isdisabled)}
        />
      </div>
    )

  }

}
