import React, { FC } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

interface PageTabsProps {
  tabs: string[];
  onClick: (tab: string) => void;
  currentTab: string;
}

const PageTabs: FC<PageTabsProps> = ({ tabs = [], onClick, currentTab }) => {

  return (
    <div className={styles['tabs-block']}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => onClick(tab)}
          className={classnames(
            styles['tabs-button'],
            styles['bt-button-small-bright-short'],
            styles['bt-elastic'],
            {[styles['active']]: currentTab === tab}
          )}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};
export default PageTabs;
