import React, { FC } from 'react';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListRadio } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import Truncate from 'react-truncate';
import sanitize from '@bt-react/libs/sanitize';
import useSWR from 'swr';
import { faCircleCheck, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import pushTagToDataLayerGA4 from '@bt-react/components/pushTagToDataLayerGA4';

// Components
import DateTime from '../Cards/CardsDateTime/CardsDateTime';
import SeriesCardImage from '../SeriesCardImage/SeriesCardImage';
import CardsCompanyLogo from '../Cards/CardsCompanyLogo/CardsCompanyLogo';

// Libraries
import sessionsCount from '../../libs/sessionsCount/sessionsCount';
import postFetch from '../../libs/postFetch';
import swrUrlParser from '../../libs/swrUrlParser/swrUrlParser';
import useSWRFetcher from '../../libs/useSWRFetcher';
import GetURLParamQuery from '../../libs/GetURLParamQuery';

// Context
import { useUserProfileContext } from '../../context/UserProfileContext';
import { useSeriesContext } from '../../context/SeriesContext';

export interface SectionFeaturedProps {
  summitData: SummitsArray[];
  type: 'summits' | 'series' | 'channel' | 'episode';
  sectionTitle: 'Summit' | 'Series' | 'Featured talk';
  dataBddTitle?: string;
  dataBddDesc?: string;
  priorityStatus?: boolean;
  imageUrl: string;
  gridLink: string;
  hostUrl: string;
}

export interface SummitsArray {
  id: number;
  title: string;
  description: string;
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  visibility: string;
  imageUrl: string;
  companyLogo: string;
  primaryColor: string;
  secondaryColor: string;
  tracks: Track[];
  featured: boolean;
}

export interface Track {
  id: number;
  name: string;
  communications: Communication[];
}

export interface Communication {
  channelId: number;
  communicationId: number;
}

const SectionFeaturedMulitReg: FC<SectionFeaturedProps> = ({
  summitData,
  type,
  sectionTitle,
  dataBddTitle,
  dataBddDesc,
  priorityStatus,
  imageUrl,
  gridLink,
  hostUrl,
}) => {
  const {
    isVariableMultiReg,
    setIsVariableMultiReg,
    modal,
    setModal,
    triggerReg,
    setTriggerReg,
    userColor,
  } = useSeriesContext();

  const { userProfile } = useUserProfileContext();

  const [spinner, setSpinner] = useState(false);

  const utmFromUrl = GetURLParamQuery();

  let recordedEpisodeLink;

  const params = {
    summitId: summitData?.['id'],
    trackId: summitData?.['tracks']?.[0]?.['id'],
  };

  // Pass our params to api and check for a response
  const fullUrl = swrUrlParser('/api/seriesEpisodesIds', params);
  const { data } = useSWR(fullUrl, useSWRFetcher());

  if (data?.lastRecordedCommid) {
    const utm = utmFromUrl ? utmFromUrl : `?utm_source=brighttalk-portal&utm_medium=web&utm_content=recorded&utm_campaign=all-series&channel_id=${data?.lastRecordedCommid?.['channelId']}`;
    recordedEpisodeLink = `${hostUrl}/webcast/${data?.lastRecordedCommid?.['channelId']}/${data?.lastRecordedCommid?.['commId']}${utm}`;
  }
  const modalPopup = () => {
    setModal(!modal);

    pushTagToDataLayerGA4('click_tt', {
      click_category: type,
      click_action: `${type} Multi Reg Popup`,
      click_subtype: modal ? 'closed' : 'open',
    });
  };

  const renderButtons = () => {
    if (isVariableMultiReg && isVariableMultiReg.includes(summitData?.['id'])) {
      return (
        <>
          <p className={styles['success-message']}>
            <span>
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="lg"
                className={styles['icon-custom-color']}
              />
            </span>{' '}
            You&#39;re registered!
          </p>
          {data?.isRecordedLive && (
            <a
              className={classnames(
                styles['bt-button-regular-primary-fluid'],
                styles['bt-elastic'],
                styles['bt-button'],
                { [styles['has-user-color']]: userColor }
              )}
              href={recordedEpisodeLink}
            >
              Watch{type == 'series' ? ' Series' : ''}
            </a>
          )}
        </>
      );
    }

    // If we have upcoming episodes we need to show the register button
    // If we have recorded talks and the registration is a success then we run
    // the first block 'hasMultiReg' which will include the watch series button
    if (data?.isUpcoming) {

      return (
        <Button
          className={classnames(
            styles['bt-button-regular-primary-fluid'],
            styles['bt-elastic'],
            styles['bt-button'],
            { [styles['has-user-color']]: userColor }
          )}
          onClick={() => {
            if (userProfile?.isLoggedIn) {
              setTriggerReg(true);
            } else {
              modalPopup();
            }
          }}
        >
          {spinner && (
            <FontAwesomeIcon
              className={classnames(
                styles['bt-icons'],
                styles['loading-spinner']
              )}
              icon={faSpinner}
              spin
              style={{ color: '#ffffff' }}
            />
          )}
          Register for {type}
        </Button>
      );
    }

    // If we have no upcoming talks only recorded we never show register button only
    // show the 'Watch series' buttton
    if (data?.isRecordedLive && data?.isUpcoming === false) {
      return (
        <a
          className={classnames(
            styles['bt-button-regular-primary-fluid'],
            styles['bt-elastic'],
            styles['bt-button'],
            { [styles['has-user-color']]: userColor }
          )}
          href={recordedEpisodeLink}
        >
          Watch{type == 'series' ? ' Series' : ''}
        </a>
      );
    }
  };

  useEffect(() => {
    const multiReg = async () => {
      setSpinner(true);
      pushTagToDataLayerGA4('click_tt', {
        click_category: 'Series',
        click_action: 'Series Multi Reg Button',
        click_subtype: 'Multi Reg Request',
      });
      const UTM =
        utmFromUrl ? utmFromUrl : '?utm_source=brighttalk-portal&utm_medium=web&utm_content=upcoming&utm_campaign=all-' +
        type;
      const data = JSON.stringify({
        summitId: summitData?.['id'],
        tracks: summitData?.['tracks'],
        referrerUrl: `${hostUrl}/channels${UTM}`,
      });

      const multiRegRes = await postFetch('/api/multiReg', {}, data);

      if (multiRegRes?.success === 'Accepted') {
        // Used to show the success message and calendar button in button block markup
        setIsVariableMultiReg([...isVariableMultiReg, summitData?.['id']]);
        pushTagToDataLayerGA4('click_tt', {
          click_category: type,
          click_action: `${type} Multi Reg Button`,
          click_subtype: 'Multi Reg Success',
        });
      } else {
        pushTagToDataLayerGA4('click_tt', {
          click_category: type,
          click_action: `${type} Multi Reg Button`,
          click_subtype: 'Multi Reg Error',
        });
      }

      setSpinner(false);
      setTriggerReg(false);
    };

    if (triggerReg) {
      multiReg();
    }
  }, [triggerReg]);

  return (
    <div
      className={classnames(styles['bt-section-featured'], 'generic-featured')}
    >
      <Row>
        <Col md="6" lg="4" className={styles['featured-left']}>
          <div
            className={classnames(
              styles['img-wrap'],
              styles['section-featured-img-holder']
            )}
          >
            <a href={gridLink}>
              <SeriesCardImage
                id={summitData['id']}
                heroImage={imageUrl}
                title={summitData['title']}
                pageType={'section-featured'}
                priorityStatus={priorityStatus}
              />
            </a>
          </div>
        </Col>
        <Col md="6" lg="8" className={styles['featured-right']}>
          <Row>
            <Col
              className={
                type === 'series' ? styles['bt-section-featured-body'] : ''
              }
            >
              <h2 className={styles['featured-section-title']}>
                Latest {sectionTitle}
              </h2>
              <h3
                data-bdd={dataBddTitle}
                className={styles['latest-webcast-title']}
              >
                <a href={gridLink}>{sanitize(summitData['title'])}</a>
              </h3>
              <p className={styles['item-meta']}>
                {type === 'summits' && (
                  <DateTime
                    dateTime={summitData['scheduledStartDate']}
                    endDate={summitData['scheduledEndDate']}
                    fromType="summit"
                  />
                )}
                {type === 'summits' && (
                  <>
                    <span className={styles['text-divider']}>|</span>
                    <span>{sessionsCount(summitData['tracks'])} sessions</span>
                  </>
                )}
                {type === 'series' && (
                  <span>
                    <FontAwesomeIcon icon={faListRadio} />{' '}
                    {sessionsCount(summitData['tracks'])} episodes
                  </span>
                )}
              </p>

              <p className={styles['description']} data-bdd={dataBddDesc}>
                <Truncate lines={2}>
                  {sanitize(summitData['description'])}
                </Truncate>
              </p>

              <div className={styles['series-button-group'] + ' tt'}>
                {renderButtons()}
              </div>
            </Col>
            {type === 'series' && (
              <Col className={styles['company-logo-container']}>
                <div className={styles['company-logo-holder']}>
                  <p className={styles['series-feat-presented-by']}>
                    Presented by
                  </p>
                  <div className={styles['company-logo']}>
                    <CardsCompanyLogo
                      href={summitData?.['companyLogo']}
                      id={summitData?.['id']}
                      title={summitData?.['title']}
                      height={42}
                      width={105}
                      objectFit={'contain'}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SectionFeaturedMulitReg;
