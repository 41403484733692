import FetchChannelSummits from '../../libs/FetchChannelSummits/FetchChannelSummits';
import FetchFeaturedChannelSummits from '../../libs/FetchFeaturedChannelSummits/FetchFeaturedChannelSummits';

export default function SingleChannelVirtualEvents({
  channelId,
  hostUrl,
  summitUrl,
}) {
  // Params to fetch the summits for the specific channel.
  const paramsToFetchSummits = {
    page: 1,
    pageSize: 6,
    type: 'summits',
    channelId: channelId,
    sortBy: 'scheduledStartDate',
  };
  // Pass necessary info for the summits component for the specific channel.
  const summitsComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
    sectionHeadingTitle: 'Summits',
    priorityStatus: true,
    summitUrl,
    summitsUTM:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-summits&utm_content=summits',
    footLinkTitle: 'See agenda',
    gridItemName: 'summit-item',
    dataBddTitle: 'talks-title-summits',
    dataBddDesc: 'talks-card-description-summits',
  };
  // Params to fetch the virtual events for the specific channel.
  const paramsToFetchVirtualEvents = {
    page: 1,
    pageSize: 6,
    type: 'virtualEvents',
    channelId: channelId,
    sortBy: 'scheduledStartDate',
  };
  const virtualEventsComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
    sectionHeadingTitle: 'Virtual events',
    priorityStatus: true,
    summitUrl,
    summitsUTM:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-virtualevents&utm_content=virtualevents',
    footLinkTitle: 'See agenda',
    gridItemName: 'summit-item',
    dataBddTitle: 'talks-title-summits',
    dataBddDesc: 'talks-card-description-summits',
  };
  // Params to fetch the virtual events for the specific channel.
  const paramsToFetchVirtualEventsPlus = {
    page: 1,
    pageSize: 6,
    type: 'virtualEventsPlus',
    channelId: channelId,
    sortBy: 'scheduledStartDate',
  };  
  // Pass necessary info for the virtual events component for the specific channel.
  const virtualEventsPlusComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
    sectionHeadingTitle: 'Featured virtual events',
    priorityStatus: true,
    summitUrl,
    summitsUTM:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-virtualevents&utm_content=virtualevents',
    footLinkTitle: 'See agenda',
    gridItemName: 'summit-item',
    dataBddTitle: 'talks-title-summits',
    dataBddDesc: 'talks-card-description-summits',
  };
  // Params to fetch the feature from looking at both Virtual Events and Summits for the specific channel (Most recent one).
  const paramsToFetchFeaturedSummitsVe = {
    page: 1,
    pageSize: 3,
    type: 'summits',
    channelId: channelId,
  };
  // UTM params for featured Virtual Events and Summits.
  const featuredSummitVeUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=all-series&utm_content=featured';
  const featuredSummitVeComponentInfo = {
    priorityStatus: true,
    seriesUTM: featuredSummitVeUTM,
    type: 'summits',
    sectionTitle: 'Summit',
  };

  return (
    <>
      {FetchFeaturedChannelSummits(
        hostUrl,
        paramsToFetchFeaturedSummitsVe,
        featuredSummitVeComponentInfo
      )}
      {FetchChannelSummits(
        hostUrl,
        paramsToFetchVirtualEvents,
        virtualEventsComponentInfo
      )}
      {FetchChannelSummits(
        hostUrl,
        paramsToFetchVirtualEventsPlus,
        virtualEventsPlusComponentInfo
      )}
      {FetchChannelSummits(hostUrl, paramsToFetchSummits, summitsComponentInfo)}
    </>
  );
}
