import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonSummitsGridItems from '../../components/listGridItems/SummitsGridItems/Skeleton';
import SummitsChannelGrid from '../../components/SummitsChannelGrid/SummitsChannelGrid';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';

// API URL.
const summitsChannelApiUrl = '/api/channelSummits';

export const FetchChannelSummits = (baseUrl, params, componentInfo) => {
  const fullUrl = swrUrlParser(summitsChannelApiUrl, params);

  const { data, error } = useSWR(fullUrl, useSWRFetcher());

  let summitsChannelData;

  if (error) {
    summitsChannelData = {
      summits: [],
      found: 0,
    };
  }

  if (!data || data[params?.type]?.length === 0) {
    summitsChannelData = {
      summits: [],
      found: 0,
    };
  } else {
    summitsChannelData = {
      summits: data[params?.type],
      found: data['found'],
      links: data['paginateLinks'],
    };
  }

  if (!data) {
    return (
      <>
        <SkeletonSectionHeadings
          skeletonHeadingDescription={false}
          headingBlockName={componentInfo?.sectionHeadingTitle}
        />
        <SkeletonSummitsGridItems />
      </>
    );
  } else if (summitsChannelData['summits']?.length > 0) {
    return (
      <>
        <SummitsChannelGrid
          SummitsGrid={summitsChannelData['summits']}
          channelId={params?.channelId}
          found={summitsChannelData['found']}
          type={params?.type}
          filter={params?.status}
          utmParams={componentInfo?.summitsUTM}
          rowColSm={componentInfo?.rowColSm}
          rowColMd={componentInfo?.rowColMd}
          rowColXl={componentInfo?.rowColXl}
          summitsFilterParams={params}
          footLinkTitle={componentInfo?.footLinkTitle}
          gridItemName={componentInfo?.gridItemName}
          dataBddTitle={componentInfo?.dataBddTitle}
          dataBddDesc={componentInfo?.dataBddDesc}
          summitUrl={componentInfo?.summitUrl}
          seriesUrl={componentInfo?.summitsUrl}
          baseUrl={baseUrl}
          priorityStatus={componentInfo?.priorityStatus}
          headingBlockName={componentInfo?.headingBlockName}
          sectionHeading={componentInfo?.sectionHeading}
          sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
          sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
          sectionHeadingDescription={componentInfo?.sectionHeadingDescription} // set as empty to disable description
          sectionHeadingLink={componentInfo?.sectionHeadingLink}
          sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
          sectionHeadingBddDesc={componentInfo?.sectionHeadingBddTitle}
          sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
          skeletonHeadingDescription={false}
          isFirstBlock={false}
          listLayout={componentInfo?.listLayout}
          cardLayout={componentInfo?.cardLayout}
          pageType={componentInfo?.pageType}
          clickableCard={componentInfo?.clickableCard}
          pageSize={params?.pageSize}
          sortBy={params?.sortBy}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default FetchChannelSummits;
