import React, { FC } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-thin-svg-icons';
//Context
import { useTalksFilters } from '../../context/TalksFilters';

const FilterNoResults: FC = () => {
  const { setTalksSearchFilter, setTalksCat } = useTalksFilters();
  
  
  // run our onclick handler and reset the talksCat & search context
  const resetFiltersNoResults = (e) => {
    setTalksCat([]); // Reset the talks categories
    setTalksSearchFilter(''); // reset the search field 
  }
  
  return (
    <div className={styles['no-results-wrap']}>
      <div className={styles['warning-icon']}>
        <FontAwesomeIcon icon={faTriangleExclamation} size={"2xl"} />
      </div>

      <div className={styles['results-message']}>
        <p>No results found</p>
      </div>

      <div className={styles['reset-button']}>
        <button
          className={classnames(
            styles['bt-button-small-bright-short'],
            styles['bt-elastic'],
            styles['svg'],
            styles['no-results-button']
          )}
          onClick={resetFiltersNoResults}
        >
          <FontAwesomeIcon icon={faRotateRight} />
          Reset filters
        </button>
      </div>
    </div>
  );
};
export default FilterNoResults;
