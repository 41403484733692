import useSWR from 'swr';

// Components
import SkeletonSectionFeatured from '../../components/SectionFeatured/Skeleton';
import SectionFeaturedMulitReg from '../../components/SectionFeatured/SectionFeaturedMultiReg';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';
import gridLink from '../gridLink';
import { useMemo } from 'react';

export const FetchFeaturedChannelSummits = (hostUrl, params, componentInfo) => {

  // API URL.
  const summitsChannelApiUrl = '/api/channelSummits';

  const summitsChannelFullUrl = swrUrlParser(summitsChannelApiUrl, params);

  // Get the channel's summits which are closestToNow.
  const { data: channelSummitsData, error: channelSummitsError } = useSWR(
    summitsChannelFullUrl,
    useSWRFetcher()
  );

  const veChannelParams = { ...params, type: 'virtualEvents' };

  const veChannelFullUrl = swrUrlParser(summitsChannelApiUrl, veChannelParams);

  // Get the channel's virtual events which are closestToNow.
  const { data: channelVirtualEventData, error: channelVirtualEventError } =
    useSWR(veChannelFullUrl, useSWRFetcher());

  const vePlusChannelParams = { ...params, type: 'virtualEventsPlus' };

  const vePlusChannelFullUrl = swrUrlParser(summitsChannelApiUrl, vePlusChannelParams);

  // Get the channel's virtual events plus which are closestToNow.
  const { data: channelVirtualEventPlusData, error: channelVirtualEventPlusError } =
    useSWR(vePlusChannelFullUrl, useSWRFetcher());

  // Combine and Sort Data
  const finalData = useMemo(() => {
    const time = Date.now();
    const summits = [
      ...(channelSummitsData?.summits || []),
      ...(channelVirtualEventData?.virtualEvents || []),
      ...(channelVirtualEventPlusData?.['virtualEventsPlus'] || []),
    ];

    return summits.sort((a, b) => {
      return Math.abs(time - new Date(a.scheduledStartDate).getTime()) - 
             Math.abs(time - new Date(b.scheduledStartDate).getTime());
    });
  }, [channelSummitsData, channelVirtualEventData, channelVirtualEventPlusData]);

  if (channelSummitsError || channelVirtualEventError || channelVirtualEventPlusError) {
    console.error('Error fetching summit data:', channelSummitsError || channelVirtualEventError);
    return null;
  }

  const setGridLink = gridLink(
    finalData?.[0]?.wordPressLink,
    finalData?.[0]?.id,
    componentInfo?.type,
    componentInfo?.seriesUTM,
    'search-featured-section',
    0,
    componentInfo?.summitUrl + '/summits/'
  );

  // Set loading const to display the skeleton until get the response back from the APIs.
  const isLoading =
    !channelSummitsData?.['summits'] &&
    !channelVirtualEventData?.['virtualEvents'];

  return (
    <>
      {(() => {
        if (isLoading) {
          return <SkeletonSectionFeatured />;
        } else if (finalData?.length > 0) {
          // Set summits featured grid link.

          return (
            <SectionFeaturedMulitReg
              summitData={finalData[0]}
              type="summits"
              sectionTitle={componentInfo?.sectionTitle}
              dataBddTitle={'featured-talk-title-summits'}
              dataBddDesc={'featured-talk-card-description-summits'}
              priorityStatus={componentInfo.priorityStatus}
              imageUrl={finalData[0]['imageUrl']}
              gridLink={setGridLink}
              hostUrl={hostUrl}
            />
          );
        } else if (finalData?.length < 1) {
          <></>;
        }
      })()}
    </>
  );
};

export default FetchFeaturedChannelSummits;
