import React, { FC } from 'react';
import { Container } from 'reactstrap';
import styles from './index.module.scss';
import classnames from 'classnames';

// Fetch components
import TalksFilterLinks from '../TalksFilterLinks/TalksFilterLinks';

//import { useParams } from 'next/navigation';

// import contexts
import { useChannelContext } from '../../context/ChannelContext';
import { useTalksFilters } from '../../context/TalksFilters';

// Libraries
import FetchChannelV2Feed from '../../libs/FetchChannelV2Feed/FetchChannelV2Feed';
import FetchFeaturedChannelTalk from '../../libs/FetchFeaturedChannelTalk/FetchFeaturedChannelTalk';
import { useParams } from 'next/navigation';

interface SingleChannelTalksProps {
  hostUrl: string;
}
const SingleChannelTalks: FC<SingleChannelTalksProps> = ({ hostUrl }) => {
  type Params = { channelId: string };
  const params = useParams<Params>();

  const { setTalksSearchFilter } = useTalksFilters();

  // Fetch the channel context data
  const { channelData } = useChannelContext();
  const channelId = channelData.id;

  // Params to fetch ChannelV2Feed
  const paramsToFetchChannelV2Feed = {
    channelId: channelId,
    //page: 1, // This can be removed as the default will be 1 unless there is more then 1000 talks.
    //size: 12, // This needs to be removed as we will get all items with initial load.
  };

  // Pass the component info for the ChannelV2Feed.
  const channelV2FeedComponentInfo = {
    gridSize: 12, // this allows us to modify the block size when we fetch all talks to begin with - can be removed after we revert to older pattern
    rowColSm: 6,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 4,
    sectionHeading: false,
    sectionHeadingTitle: '',
    priorityStatus: true,
    footLinkTitle: '',
    gridItemName: 'channel-talk-item',
    dataBddTitle: 'talks-title-channel',
    dataBddDesc: 'talks-card-description-channel',
    talksUtm:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=',
  };

  // UTM params for featured single channel talk.
  const featuredChannelTalkUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=featured';

  /**
   * Function to prepare the params for featured talk in channel.
   * @returns featured channel params.
   */

  const paramsToFetchDataFeaturedTalk = {
    page: '1',
    filter: 'closestToNow',
    size: '1',
    channelId: params?.channelId,
  };
  const featuredChannelTalkComponentInfo = {
    priorityStatus: true,
    channelTalkUTM: featuredChannelTalkUTM,
    type: 'channel',
    channelId: params?.channelId,
  };

  const handleSearchFilterOnChange = (e) => {
    const { value } = e.target;
    setTalksSearchFilter(value);
  };

  return (
    <Container
      className={classnames('container-fluid m-0 p-0', styles['bt-container'])}
    >
      {FetchFeaturedChannelTalk(
        paramsToFetchDataFeaturedTalk,
        featuredChannelTalkComponentInfo
      )}
      <div className={styles['channel-talk-block-container']}>
        <div className={styles['talkblock-sidebar']}>
          <TalksFilterLinks
            handleSearchFilterOnChange={handleSearchFilterOnChange}
          />
        </div>

        <div id="main-container" className={styles['talkblock-main']}>
          {FetchChannelV2Feed(
            hostUrl,
            paramsToFetchChannelV2Feed,
            channelV2FeedComponentInfo
          )}
        </div>
      </div>
    </Container>
  );
};
export default SingleChannelTalks;
