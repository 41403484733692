import { Communication } from '../../components/listGridItems/TalksGridItems/ChannelFilterTalksGridItems';

/**
 * Function to filter the talks.
 * @param {Communication[]} talkData - List of talks
 * @param {string} phrase - Search keyword to filter talks
 * @param {string[]} categoriesToFilter - Categories to filter talks
 */
function filterChannelList(
  talkData: Communication[],
  phrase: string,
  categoriesToFilter: string[]
) {
  if (!talkData) {
    return [];
  }

  // Function to get the term from the categories array.
  const getCategories = (categories) => {
    let category = [];
    categories &&
      categories.forEach(({ label, term }) => {
        if (label === 'category') {
          category.push(term.toLowerCase());
        }
      });
    return category;
  };

  // Here, filtering listOfTalksToFilter and return the filtered talks.
  const filteredTalks = talkData?.filter(function ({
    title,
    summary,
    status,
    categories,
  }) {
    let passedFilters = false;
    // Check whether the phrase matches the title and description.
    const hasPhrase = phrase?.length
      ? title?.toLowerCase().includes(phrase.toLowerCase()) ||
        summary?.toLowerCase().includes(phrase.toLowerCase())
      : false;

    // Check whether the type selected.
    const typeSelected =
      categoriesToFilter.includes('type/upcoming') ||
      categoriesToFilter.includes('type/recorded');

    // Check whether the category selected.
    const categorySelected = categoriesToFilter.filter(
      (e) => e !== 'type/upcoming' && e !== 'type/recorded'
    );

    // Check whether the type matches the status.
    const hasType = categoriesToFilter.includes(`type/${status}`);

    // Get categories from channel V2.
    const feedCategories = getCategories(categories);

    // Check whether the talks has the category.
    const hasCategory = categorySelected.every(
      (selected) =>
        feedCategories.some(
          (fromAPI) => selected == fromAPI
        )
    );

    // If the type is being selected.
    if (typeSelected) {
      // Enable the passedFilters if the following conditions met.
      // if the webcast has the same type(status).
      if (hasType) passedFilters = true;

      // Disable the passedFilters if the following conditions met
      // if there is phrase and passedFilters is true and if phrase does not matches the title/description.
      if (phrase?.length && passedFilters && !hasPhrase) passedFilters = false;

      // Enable/Disable the passedFilters(based on the hasCategory value) if the following conditions met
      // if there is category selected (not the type) and passedFilters
      if (categorySelected?.length && passedFilters)
        passedFilters = hasCategory;
    } else if (phrase) {
      // Enable the passedFilters if the following conditions met.
      // if the there is phrase and phrase matches the title/description.
      if (phrase && hasPhrase) passedFilters = true;

      // Enable/Disable the passedFilters(based on the hasCategory value) if the following conditions met
      // if there is category selected (not the type) and passedFilters.
      if (categorySelected?.length && passedFilters)
        passedFilters = hasCategory;
    } else if (categorySelected?.length) {
      // Enable/Disable the passedFilters based on the hasCategory value.
      passedFilters = hasCategory;
    }
    // Return the passed filter to filter the item.
    return passedFilters;
  });

  // Return the filtered data.
  return filteredTalks || [];
}

export default filterChannelList;
